import type { AutoCompleteCityDetails } from 'src/models/city.model';

import type { UseQueryConfig } from 'src/utils/client/ClientAPIUtils';
import { usePatchedSWR } from 'src/utils/client/ClientAPIUtils';
import { performServerAPICall } from 'src/utils/server/ServerAPIUtils';

export type AutocompleteCitiesQueryData = AutoCompleteCityDetails[];
export type AutocompleteCitiesQueryVariables = {
  searchQuery?: string;
  latitude?: number;
  longitude?: number;
};

export const useAutoCompleteCitiesQuery = ({
  params: { searchQuery, latitude, longitude },
  ...config
}: UseQueryConfig<
  AutocompleteCitiesQueryData,
  AutocompleteCitiesQueryVariables
>) =>
  usePatchedSWR<AutocompleteCitiesQueryData>(
    {
      url: 'geo/cities',
      params: {
        search: searchQuery,
        'geoLocalization[lat]': latitude,
        'geoLocalization[lon]': longitude,
      },
    },
    config,
  );

export const getAutocompleteCities = (latitude: string, longitude: string) =>
  performServerAPICall<AutocompleteCitiesQueryData>({
    url: 'geo/cities',
    params: {
      'geoLocalization[lat]': latitude,
      'geoLocalization[lon]': longitude,
    },
  });
