import React, { useRef, useState, useEffect } from 'react';
import type { HTMLProps, MouseEvent, ReactNode } from 'react';

import Form from 'src/components/ui/Form';
import type { FormControlProps } from 'src/components/ui/Form/components/Control';

import Icon from '../Icon/Icon';
import style from './Autocomplete.module.scss';

import {
  getDataTestId,
  mergeClassNames,
  useClickOutside,
} from 'src/utils/ReactUtils';

type AutocompleteProps<T> = {
  items: T[] | undefined;
  onClickItem: (value: T) => void;
  onTyping: (value: string) => void;
  labelDisplay: (value: T) => string;
  labelInputAfterSelect: (value: T) => string;
  initialValue?: string;
  clear?: boolean;
  menuClassName?: string;
  dataTestId?: string;
  wrapperClassName?: string;
  openClassName?: string;
  isOpenInputClassName?: string;
  defaultItemComponent?: ReactNode;
} & HTMLProps<HTMLInputElement>;
// eslint-disable-next-line @typescript-eslint/ban-types
const Autocomplete = <T extends object>(props: AutocompleteProps<T>) => {
  const {
    items,
    onClickItem,
    onTyping,
    labelDisplay,
    labelInputAfterSelect,
    initialValue,
    clear,
    menuClassName,
    className,
    wrapperClassName,
    dataTestId,
    openClassName,
    defaultItemComponent,
    isOpenInputClassName,
    ...formControlProps
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [currentValue, setCurrentValue] = useState(initialValue ?? '');
  const wrapperRef = useRef<HTMLDivElement>(null);
  useClickOutside(wrapperRef, () => setIsOpen(false));

  useEffect(() => {
    if (initialValue) {
      setCurrentValue(initialValue ?? '');
    }
  }, [initialValue]);

  useEffect(() => {
    if (clear) {
      setCurrentValue('');
    }
  }, [clear]);

  const handleTypingAutocomplete = (value: string) => {
    setCurrentValue(value);
    onTyping(value);
    setIsOpen(true);
  };
  const handleMouseDownAutocomplete = (event: MouseEvent<HTMLInputElement>) => {
    // Fix Webkit bug with autocomplete being hidden behind the URL bar on mobile
    if ('touchstart' in window) {
      event.preventDefault();
      event.currentTarget.blur();
    }

    setIsOpen(true);
  };
  const handleClickItem = (element: T) => {
    setIsOpen(false);
    onClickItem(element);
    setCurrentValue(labelInputAfterSelect(element));
  };

  return (
    <div
      className={mergeClassNames([
        wrapperClassName,
        style.wrapper,
        isOpen && style.isOpen,
        isOpen && openClassName,
      ])}
      ref={wrapperRef}
      {...getDataTestId({ dataTestId, prefix: 'autocomplete' })}
    >
      <span className={style.autoCompleteMobileHeader}>
        <Icon
          name="fleche-g"
          height={30}
          width={30}
          color="inherit"
          className={style.autoCompleteMobileHeaderIcon}
          onClick={() => setIsOpen(false)}
          dataTestid="autocomplete_back"
        />
        <Form.Control
          {...(formControlProps as FormControlProps)}
          type="text"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleTypingAutocomplete(e.target.value)
          }
          onMouseDown={handleMouseDownAutocomplete}
          className={mergeClassNames([
            style.autoCompleteInput,
            className,
            isOpen && isOpenInputClassName,
          ])}
          value={currentValue}
          autoFocus={isOpen}
          {...getDataTestId({ dataTestId, prefix: 'input' })}
        />
        {!!defaultItemComponent ? (
          <span className={style.defaultItemWrapper}>
            {defaultItemComponent}
          </span>
        ) : currentValue.length ? (
          <button
            type={'button'}
            onClick={() => setCurrentValue('')}
            className={style.iconEraseWrapper}
          >
            <Icon
              name="croix"
              height={16}
              width={16}
              color="inherit"
              className={style.searchEraseIcon}
            />
          </button>
        ) : (
          <div className={style.searchIconWrapper}>
            <Icon
              name="recherche"
              height={25}
              width={25}
              color="inherit"
              className={style.searchIcon}
            />
          </div>
        )}
      </span>
      {isOpen && !!currentValue.length && (
        <div className={mergeClassNames([style.resultWrapper, menuClassName])}>
          <ul className={style.autocompleteList}>
            {!!defaultItemComponent && (
              <li className={`${style.autoCompleteItem} ${style.defaultItem}`}>
                {defaultItemComponent}
              </li>
            )}
            {!!currentValue.length && items?.length
              ? items?.map((element, index) => (
                  <li
                    key={index}
                    className={style.autoCompleteItem}
                    onClick={() => handleClickItem(element)}
                    {...getDataTestId({ dataTestId, prefix: 'list_item' })}
                  >
                    {labelDisplay(element)}
                  </li>
                ))
              : null}
          </ul>
        </div>
      )}
    </div>
  );
};
export default Autocomplete;
