import { camelize, decamelize } from 'humps';
import type { NextRouter } from 'next/router';
import { useRouter } from 'next/router';
import type { ParsedQs } from 'qs';
import type { ChangeEvent } from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import {
  hashQueryParameters,
  parseQueryStringHash,
} from 'src/utils/StringUtils';

type FilterBase = {
  key: string;
  label: string;
};

export type SelectedFilter = {
  key: string;
  subKey?: string;
  value: string;
  label: string | undefined;
};

export type Filter = FilterBase & {
  possibleValues: string[] | FilterBase[] | Record<string, FilterBase[]>;
};

export const isFilterSelected = ({
  searchParams,
  key,
  subKey,
  value,
}: {
  searchParams: ParsedQs | undefined;
  key: string;
  subKey?: string;
  value: string;
}) => {
  const keyDecamelized = decamelize(key);

  if (!searchParams?.[keyDecamelized]) {
    return false;
  }

  if (!subKey) {
    return typeof searchParams[keyDecamelized] === 'string'
      ? searchParams[keyDecamelized] === value
      : !!(searchParams[keyDecamelized] as string[] | undefined)?.includes(
          value,
        );
  }

  return typeof (
    searchParams[keyDecamelized] as Record<string, string> | undefined
  )?.[subKey] === 'string'
    ? (searchParams[keyDecamelized] as Record<string, string> | undefined)?.[
        subKey
      ] === value
    : !!(
        (
          searchParams[keyDecamelized] as Record<string, string[]> | undefined
        )?.[subKey] as string[] | undefined
      )?.includes(value);
};

export const getFilterValue = ({
  searchParams,
  key,
}: {
  searchParams: ParsedQs | undefined;
  key: string;
}) => {
  const keyDecamelized = decamelize(key);
  return !!searchParams?.[keyDecamelized] &&
    typeof searchParams[keyDecamelized] === 'string'
    ? (searchParams[keyDecamelized] as string)
    : undefined;
};

export const getFilterPossibleValue = ({
  filters,
  key,
  subKey,
  value,
}: {
  filters: Record<string, Filter | null> | undefined;
  key: string;
  subKey?: string;
  value: string | Filter;
}) => {
  const camelizedKey = camelize(key);
  const camelizedSubKey = subKey ? camelize(subKey) : undefined;

  const filter =
    filters && camelizedKey in filters
      ? filters[camelizedKey]?.possibleValues
      : null;
  const filterWithSubKey =
    !camelizedSubKey || !filter || !(camelizedSubKey in filter)
      ? filter
      : (filter as unknown as Record<string, Filter | null>)[camelizedSubKey];

  if (!filterWithSubKey) {
    return typeof value === 'string' ? value : value.key;
  }

  const possibleValues = Array.isArray(filterWithSubKey)
    ? filterWithSubKey
    : Object.values(filterWithSubKey).flat();

  return (possibleValues as (string | Filter)[]).find((val) => {
    const valKey = typeof val === 'string' ? val : val.key;

    // filter is a string
    return typeof value === 'string'
      ? value === valKey
      : // filter is an object
        Object.values(value).includes(valKey);
  });
};

export const getLabelSelectedFilter = (
  key: string,
  possibleValue: string | undefined | Filter,
) => {
  if (!possibleValue) {
    return undefined;
  }
  switch (key) {
    case 'critair_levels':
      return `Critair ${possibleValue}`;
  }
  return typeof possibleValue === 'string'
    ? possibleValue
    : possibleValue.label;
};

const getSelectedFilterValues = ({
  searchParams,
  filters,
}: {
  searchParams: ParsedQs | undefined;
  filters: Record<string, Filter | null> | undefined;
}) => {
  if (!searchParams || !filters) {
    return [];
  }

  return Object.entries(searchParams).flatMap(([key, value]) => {
    // Filter is a string
    if (typeof value === 'string') {
      const possibleValue = getFilterPossibleValue({
        filters,
        key,
        value,
      });

      return {
        key,
        value,
        label: getLabelSelectedFilter(key, possibleValue),
      };
    }

    // Filter is an array
    if (Array.isArray(value)) {
      return (value as string[]).map((subValue) => {
        const possibleValue = getFilterPossibleValue({
          filters,
          key,
          value: subValue,
        });

        return {
          key,
          value: subValue,
          label: getLabelSelectedFilter(key, possibleValue),
        };
      });
    }

    // Filter is an object (versions)
    return Object.entries(value as Record<string, string | string[]>).flatMap(
      ([subKey, subValue]) => {
        if (typeof subValue === 'string') {
          const possibleValue = getFilterPossibleValue({
            filters,
            key,
            subKey,
            value: subValue,
          });

          return {
            key,
            value: subValue,
            label: getLabelSelectedFilter(key, possibleValue),
            subKey,
          };
        }

        return subValue.map((subSubValue) => {
          const possibleValue = getFilterPossibleValue({
            filters,
            key,
            subKey,
            value: subSubValue,
          });

          return {
            key,
            subKey,
            value: subSubValue,
            label: getLabelSelectedFilter(key, possibleValue),
          };
        });
      },
    );
  });
};

const routerPush = ({
  router,
  newSearch,
  newPathname,
}: {
  router: NextRouter;
  newSearch: string;
  newPathname: string | undefined;
}) => {
  const { page, search, ...queryWithoutPageAndSearch } = router.query;

  router.push(
    {
      query: {
        ...queryWithoutPageAndSearch,
        ...(newSearch && { search: newSearch }),
      },
      ...(newPathname && { pathname: newPathname }),
    },
    undefined,
    { shallow: true },
  );
};

type QueryParameters = Record<
  string,
  string | ParsedQs | string[] | ParsedQs[] | (string | ParsedQs)[] | undefined
>;

type QueryParametersReturnWrapper = (params: {
  searchParams: ParsedQs | undefined;
  key: string;
  value: string;
  queryParameters: QueryParameters;
}) => QueryParameters;
type QueryParametersReturnWrapperNoValue = (params: {
  searchParams: ParsedQs | undefined;
  key: string;
  queryParameters: QueryParameters;
}) => QueryParameters;

const noOp = (params: {
  searchParams: ParsedQs | undefined;
  key: string;
  value: string;
  queryParameters: QueryParameters;
}) => params.queryParameters;
const noOpNoValue = (params: {
  searchParams: ParsedQs | undefined;
  key: string;
  queryParameters: QueryParameters;
}) => params.queryParameters;

export const usePaginationFilters = ({
  filters,
  getNewQueryParametersOnAddCheckboxFilter = noOp,
  getNewQueryParametersOnRemoveCheckboxFilter = noOp,
  getNewQueryParametersOnAddNestedCheckboxFilter = noOp,
  getNewQueryParametersOnRemoveNestedCheckboxFilter = noOp,
  getNewQueryParametersOnAddTextFilter = noOp,
  getNewQueryParametersOnRemoveTextFilter = noOpNoValue,
}: {
  filters: Record<string, Filter | null>;
  getNewQueryParametersOnAddCheckboxFilter?: QueryParametersReturnWrapper;
  getNewQueryParametersOnRemoveCheckboxFilter?: QueryParametersReturnWrapper;
  getNewQueryParametersOnAddNestedCheckboxFilter?: QueryParametersReturnWrapper;
  getNewQueryParametersOnRemoveNestedCheckboxFilter?: QueryParametersReturnWrapper;
  getNewQueryParametersOnAddTextFilter?: QueryParametersReturnWrapper;
  getNewQueryParametersOnRemoveTextFilter?: QueryParametersReturnWrapperNoValue;
}) => {
  const router = useRouter();

  // Conserve object and functions identity with the latest ref technique (safe in event handlers)
  const paramsRef = useRef({
    filters,
    getNewQueryParametersOnAddCheckboxFilter,
    getNewQueryParametersOnRemoveCheckboxFilter,
    getNewQueryParametersOnAddNestedCheckboxFilter,
    getNewQueryParametersOnRemoveNestedCheckboxFilter,
    getNewQueryParametersOnAddTextFilter,
    getNewQueryParametersOnRemoveTextFilter,
  });

  useEffect(() => {
    paramsRef.current = {
      filters,
      getNewQueryParametersOnAddCheckboxFilter,
      getNewQueryParametersOnRemoveCheckboxFilter,
      getNewQueryParametersOnAddNestedCheckboxFilter,
      getNewQueryParametersOnRemoveNestedCheckboxFilter,
      getNewQueryParametersOnAddTextFilter,
      getNewQueryParametersOnRemoveTextFilter,
    };
  }, [
    filters,
    getNewQueryParametersOnAddCheckboxFilter,
    getNewQueryParametersOnAddNestedCheckboxFilter,
    getNewQueryParametersOnAddTextFilter,
    getNewQueryParametersOnRemoveCheckboxFilter,
    getNewQueryParametersOnRemoveNestedCheckboxFilter,
    getNewQueryParametersOnRemoveTextFilter,
  ]);

  /*
   ** searchParams example:
   ** {
   **   brand_labels: ['audi', 'bmw'],
   **   sort: 'price:asc',
   **   version_labels: {
   **     audi: ['rs3', 'a1'],
   **     bmw: 'x1',
   **   },
   ** }
   */
  const searchParams = useMemo(
    () =>
      parseQueryStringHash(router.query, {
        keepCommas: false,
      }),
    [router.query],
  );
  /*
   ** searchParamsComma example:
   ** {
   **   brand_labels: 'audi,bmw',
   **   sort: 'price:asc',
   **   version_labels: {
   **     audi: 'rs3,a1',
   **     bmw: 'x1',
   **   },
   ** }
   */
  const searchParamsComma = useMemo(
    () =>
      parseQueryStringHash(router.query, {
        keepCommas: true,
      }),
    [router.query],
  );

  /*
   ** selectedFilterValues example:
   ** [
   **   {
   **     key: 'brand_labels',
   **     value: 'audi',
   **     label: 'Audi'
   **   },
   **   {
   **     key: 'brand_labels',
   **     value: 'bmw',
   **     label: 'BMW'
   **   },
   **   {
   **     key: 'sort',
   **     value: 'price:asc',
   **     label: 'price:asc'
   **   },
   **   {
   **     key: 'version_labels',
   **     subKey: 'audi',
   **     value: 'rs3',
   **     label: 'RS3'
   **   },
   **   {
   **     key: 'version_labels',
   **     subKey: 'audi',
   **     value: 'a1',
   **     label: 'A1'
   **   },
   **   {
   **     key: 'version_labels',
   **     subKey: 'bmw'
   **     value: 'x1',
   **     label: 'X1',
   **   }
   ** ]
   */
  const selectedFilterValues = useMemo(
    () =>
      getSelectedFilterValues({
        searchParams,
        filters: paramsRef.current.filters,
      }),
    [searchParams],
  );

  const page =
    typeof router.query.page === 'string' ? parseInt(router.query.page, 10) : 1;

  const getPageLink = useCallback(
    (newPage: number) => {
      const { page: currentPage, ...newQuery } = router.query;

      return {
        href: router.pathname,
        query: {
          ...newQuery,
          ...(newPage !== 1 && { page: newPage }),
        },
      };
    },
    [router.pathname, router.query],
  );

  const handleAddCheckboxFilter = useCallback(
    ({
      key,
      value,
      newPathname,
    }: {
      key: string;
      value: string;
      newPathname?: string;
    }) => {
      const filterValue = searchParams?.[key] ?? [];
      const filterValueArray = Array.isArray(filterValue)
        ? filterValue
        : [filterValue];
      const newValue = [...filterValueArray, value];
      const newSearch = hashQueryParameters(
        paramsRef.current.getNewQueryParametersOnAddCheckboxFilter({
          searchParams,
          key,
          value,
          queryParameters: {
            ...searchParams,
            [key]: newValue,
          },
        }),
      );

      routerPush({ router, newSearch, newPathname });
    },
    [router, searchParams],
  );

  const handleRemoveCheckboxFilter = useCallback(
    ({
      key,
      value,
      newPathname,
    }: {
      key: string;
      value: string;
      newPathname?: string;
    }) => {
      const filterValue = searchParams?.[key] ?? [];
      const filterValueArray = Array.isArray(filterValue)
        ? filterValue
        : [filterValue];
      const newValue = filterValueArray.filter((val) => val !== value);
      const newSearch = hashQueryParameters(
        paramsRef.current.getNewQueryParametersOnRemoveCheckboxFilter({
          searchParams,
          key,
          value,
          queryParameters: {
            ...searchParams,
            [key]: newValue.length ? newValue : undefined,
          },
        }),
      );

      routerPush({ router, newSearch, newPathname });
    },
    [router, searchParams],
  );

  const handleChangeCheckboxFilter = useCallback(
    ({ value }: { value: string }) =>
      (e: ChangeEvent<HTMLInputElement>) => {
        const { name: key, checked } = e.target;

        if (checked) {
          handleAddCheckboxFilter({ key, value });
        } else {
          handleRemoveCheckboxFilter({ key, value });
        }
      },
    [handleAddCheckboxFilter, handleRemoveCheckboxFilter],
  );

  const handleAddNestedCheckboxFilter = useCallback(
    ({
      key,
      subKey,
      value,
      newPathname,
    }: {
      key: string;
      subKey: string;
      value: string;
      newPathname?: string;
    }) => {
      const filterValue =
        (searchParams?.[key] as Record<string, string> | undefined)?.[subKey] ??
        [];
      const filterValueArray = Array.isArray(filterValue)
        ? filterValue
        : [filterValue];
      const newValue = [...filterValueArray, value];
      const newSearch = hashQueryParameters(
        paramsRef.current.getNewQueryParametersOnAddNestedCheckboxFilter({
          searchParams,
          key,
          value,
          queryParameters: {
            ...searchParams,
            [key]: {
              ...(searchParams?.[key] as Record<string, string> | undefined),
              [subKey]: newValue,
            },
          },
        }),
      );

      routerPush({ router, newSearch, newPathname });
    },
    [router, searchParams],
  );

  const handleRemoveNestedCheckboxFilter = useCallback(
    ({
      key,
      subKey,
      value,
      newPathname,
    }: {
      key: string;
      subKey: string;
      value: string;
      newPathname?: string;
    }) => {
      const filterValue =
        (searchParams?.[key] as Record<string, string> | undefined)?.[subKey] ??
        [];
      const filterValueArray = Array.isArray(filterValue)
        ? filterValue
        : [filterValue];
      const newValue = filterValueArray.filter((val) => val !== value);
      const newSearch = hashQueryParameters(
        paramsRef.current.getNewQueryParametersOnRemoveNestedCheckboxFilter({
          searchParams,
          key,
          value,
          queryParameters: {
            ...searchParams,
            [key]: {
              ...(searchParams?.[key] as Record<string, string> | undefined),
              [subKey]: newValue.length ? newValue : undefined,
            },
          },
        }),
      );

      routerPush({ router, newSearch, newPathname });
    },
    [router, searchParams],
  );

  const handleChangeNestedCheckboxFilter = useCallback(
    ({ subKey, value }: { subKey: string; value: string }) =>
      (e: ChangeEvent<HTMLInputElement>) => {
        const { name: key, checked } = e.target;

        if (checked) {
          handleAddNestedCheckboxFilter({ key, subKey, value });
        } else {
          handleRemoveNestedCheckboxFilter({ key, subKey, value });
        }
      },
    [handleAddNestedCheckboxFilter, handleRemoveNestedCheckboxFilter],
  );

  const handleAddTextFilter = useCallback(
    ({
      key,
      value,
      newPathname,
    }: {
      key: string;
      value: string;
      newPathname?: string;
    }) => {
      const newSearch = hashQueryParameters(
        paramsRef.current.getNewQueryParametersOnAddTextFilter({
          searchParams,
          key,
          value,
          queryParameters: {
            ...searchParams,
            [key]: value,
          },
        }),
      );

      routerPush({ router, newSearch, newPathname });
    },
    [router, searchParams],
  );

  const handleRemoveTextFilter = useCallback(
    ({ key, newPathname }: { key: string; newPathname?: string }) => {
      const newSearch = hashQueryParameters(
        paramsRef.current.getNewQueryParametersOnRemoveTextFilter({
          searchParams,
          key,
          queryParameters: {
            ...searchParams,
            [key]: undefined,
          },
        }),
      );

      routerPush({ router, newSearch, newPathname });
    },
    [router, searchParams],
  );

  const handleChangeTextFilter = useCallback(
    ({ key, value }: { key: string; value: string }) => {
      if (value !== '') {
        handleAddTextFilter({ key, value });
      } else {
        handleRemoveTextFilter({ key });
      }
    },
    [handleAddTextFilter, handleRemoveTextFilter],
  );

  const handleSetObjectFilter = useCallback(
    ({
      value,
      newPathname,
    }: {
      value: Record<string, string | undefined>;
      newPathname?: string;
    }) => {
      const newSearch = hashQueryParameters({
        ...searchParams,
        ...value,
      });

      routerPush({ router, newSearch, newPathname });
    },
    [router, searchParams],
  );

  const handleClearAllFilters = useCallback(() => {
    router.push(
      {
        query: {},
      },
      undefined,
      { shallow: true },
    );
  }, [router]);

  return useMemo(
    () => ({
      filters,
      searchParams,
      searchParamsComma,
      selectedFilterValues,
      page,
      getPageLink,
      handleAddCheckboxFilter,
      handleRemoveCheckboxFilter,
      handleChangeCheckboxFilter,
      handleAddNestedCheckboxFilter,
      handleRemoveNestedCheckboxFilter,
      handleChangeNestedCheckboxFilter,
      handleAddTextFilter,
      handleRemoveTextFilter,
      handleChangeTextFilter,
      handleSetObjectFilter,
      handleClearAllFilters,
    }),
    [
      filters,
      getPageLink,
      handleAddCheckboxFilter,
      handleAddNestedCheckboxFilter,
      handleAddTextFilter,
      handleChangeCheckboxFilter,
      handleChangeNestedCheckboxFilter,
      handleChangeTextFilter,
      handleClearAllFilters,
      handleRemoveCheckboxFilter,
      handleRemoveNestedCheckboxFilter,
      handleRemoveTextFilter,
      handleSetObjectFilter,
      page,
      searchParams,
      searchParamsComma,
      selectedFilterValues,
    ],
  );
};
